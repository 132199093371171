import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';

import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import couponService, { Coupon } from 'services/coupon';

import CouponDetails from './components/CouponDetails';
import CouponError from './components/CouponError';
import RedemptionSuccess from './components/RedemptionSuccess';
import { CouponErrorCodes } from './types';
import Modal from 'components/Modal';
import ModalAcceptNewTerms from 'components/ModalAcceptNewTerms';

function CouponPage() {
  const { session } = useAuth();
  const { coupon_code } = useParams();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRedeemedCoupon, setIsRedeemedCoupon] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Coupon | null>();
  const [couponErrorCode, setCouponErrorCode] =
    useState<CouponErrorCodes | null>(null);
  const [availableMoney, setAvaliableMoney] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const coupon = await couponService.getCouponDetails({
        coupon_code: coupon_code ?? '',
        token: session?.access_token!,
      });
      if (coupon.successful) {
        setCoupon(coupon.data);
        if (
          coupon.data.termsAndConditions &&
          coupon.data.termsAndConditions.has_new_terms
        ) {
          setShowModal(true);
        }
      } else {
        Sentry.captureException(coupon.errors[0], {
          tags: {
            shopkeeper_id: session?.shopkeeper_id ?? 0,
            coupon_code: coupon_code ?? '',
            flow: 'get_coupon_data',
          },
        });
        setCouponErrorCode(coupon.errors[0].code);
      }
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          coupon_code: coupon_code ?? '',
          flow: 'get_coupon_data',
        },
      });
      setCouponErrorCode(error.code);
    }
    setIsLoading(false);
  }, [coupon_code, session?.shopkeeper_id]);

  const redeemCoupon = useCallback(async () => {
    try {
      setIsLoading(true);
      TagManager.dataLayer({
        dataLayer: {
          event: 'intention_redemption',
          coupon: coupon_code,
          status: coupon?.status,
        },
      });
      const resp = await couponService.redeemCoupon({
        amount: coupon?.amount ?? 1,
        coupon_code: coupon_code ?? '',
        shopkeeper_id: session?.shopkeeper_id ?? 0,
      });
      if (resp.successful) {
        const { available_money } = resp.data;
        setAvaliableMoney(available_money || 0);
        TagManager.dataLayer({
          dataLayer: {
            event: 'coupon_redeemed',
            coupon: coupon_code,
            status: coupon?.status,
          },
        });
        setIsRedeemedCoupon(true);
      } else {
        Sentry.captureException(resp.errors[0], {
          tags: {
            shopkeeper_id: session?.shopkeeper_id ?? 0,
            coupon_code: coupon_code ?? '',
            flow: 'redeem_coupon',
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'user_flow',
            category: 'system_message',
            action: 'error',
            label: resp.message,
          },
        });
        toast({
          action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
          description: resp.message,
          duration: 3000,
          variant: 'destructive',
        });
      }
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          coupon_code: coupon_code ?? '',
          flow: 'redeem_coupon',
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'error',
          label: error.message,
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: error.message,
        duration: 3000,
        variant: 'destructive',
      });
    }
    setIsLoading(false);
  }, [
    coupon?.amount,
    coupon?.status,
    coupon_code,
    session?.shopkeeper_id,
    toast,
  ]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-canjear-cupon',
        pageTitle: 'Tendero: Canjear cupon',
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Helmet>
        <title>Detalle de cupón | Descuentón</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout
          title="Detalle de cupón"
          variant={couponErrorCode || isRedeemedCoupon ? 'white' : 'light'}
        >
          <section className="flex w-full flex-1 flex-col sm:mx-auto sm:max-w-sm">
            {coupon && !isRedeemedCoupon && (
              <CouponDetails coupon={coupon} redeemCoupon={redeemCoupon} />
            )}
            <Modal open={showModal}>
              <ModalAcceptNewTerms
                onClose={() => setShowModal(false)}
                public_content_id={
                  coupon?.termsAndConditions?.public_content_id!
                }
              />
            </Modal>
            {isRedeemedCoupon && (
              <RedemptionSuccess
                amount={coupon?.amount}
                available_money={availableMoney}
              />
            )}
            {couponErrorCode && !isRedeemedCoupon && (
              <CouponError code={couponErrorCode} />
            )}
          </section>
        </Layout>
      )}
    </>
  );
}

export default CouponPage;
