import authClient from 'services/clients/authClient';

import {
  GetCouponDetailsRequest,
  GetCouponDetailsResponse,
  RedeemCouponRequest,
  RedeemCouponResponse,
} from './types';

export const getCouponDetails = async ({
  coupon_code,
  token,
}: GetCouponDetailsRequest): Promise<GetCouponDetailsResponse> => {
  return await authClient.get(`v1/coupon-code/${coupon_code}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const redeemCoupon = async ({
  amount,
  coupon_code,
  shopkeeper_id,
}: RedeemCouponRequest): Promise<RedeemCouponResponse> => {
  return await authClient.post(`v1/redeem-coupon/${shopkeeper_id}`, {
    code: coupon_code,
    amount,
  });
};
