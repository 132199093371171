import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { Button } from 'components/Button';
import Layout from 'components/Layout';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import useRedemptionHistoryStore from 'stores/redemption-history';

import CouponFinder from './components/CouponFinder';
import Loader from './components/Loader';
import Modal from 'components/Modal';
import ModalAcceptTermsFirstTime from 'components/ModalAcceptTermsFirstTime';

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { isError, isLoading, session, shopkeeper, getShopkeeperProfile } =
    useAuth();
  const redemptions = useRedemptionHistoryStore(
    useShallow((state) => state.redemptions),
  );
  const fetchRedemptionHistory = useRedemptionHistoryStore(
    useShallow((state) => state.fetchRedemptionHistory),
  );

  const fetchData = useCallback(async () => {
    await getShopkeeperProfile();
  }, [getShopkeeperProfile]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-encuentra-el-cupon',
        pageTitle: 'Tendero: Encuentra el cupon',
      },
    });
  }, []);

  useEffect(() => {
    if (!shopkeeper && !isLoading && !isError) {
      fetchData();
    }
  }, [fetchData, isError, isLoading, shopkeeper]);

  useEffect(() => {
    fetchRedemptionHistory(session?.shopkeeper_id!).catch((error) => {
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: error.message,
        duration: 3000,
        variant: 'destructive',
      });
    });
  }, [fetchRedemptionHistory, session?.shopkeeper_id, toast]);

  useEffect(() => {
    if (!session?.has_accepted_terms || session.is_first_login) {
      setIsModalOpen(true);
    }
  }, [session?.has_accepted_terms, session?.is_first_login]);

  const goMovements = () => {
    navigate('/movements');
  };

  return (
    <>
      <Helmet>
        <title>Encuentra el cupón | Descuentón</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout variant="dark">
          <section className="flex w-full flex-col gap-2.5 px-4 pb-8 pt-4 sm:mx-auto sm:max-w-sm sm:px-0">
            <div>
              <h1 className="font-serif text-2xl font-bold text-white">
                ¡Hola, te damos la bienvenida!
              </h1>
              <div className="mt-4 py-1">
                <p className="text-sm font-bold text-white">
                  Bodega: {shopkeeper?.warehouse_number}
                </p>
                <p className="text-sm font-bold text-white">
                  NUD: {shopkeeper?.NUD}
                </p>
              </div>
            </div>
            <div>
              <p className="text-sm font-bold text-white">Saldo disponible:</p>
              <p className="px-2 py-1 font-serif text-3xl font-semibold text-tertiary">
                ${redemptions.total_revenue}
              </p>
              <p className="text-xs text-tertiary">
                Actualizado el {redemptions.lastMovement_format_date}{' '}
                {redemptions.last_movement_time}
              </p>
            </div>
            <CouponFinder />
            <div className="mt-3.5 text-center">
              <Button
                className="min-w-52"
                onClick={goMovements}
                variant="secondary"
              >
                Movimientos
              </Button>
            </div>
          </section>
          <Modal open={isModalOpen}>
            <ModalAcceptTermsFirstTime onClose={() => setIsModalOpen(false)} />
          </Modal>
        </Layout>
      )}
    </>
  );
}

export default HomePage;
