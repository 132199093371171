import authClient from 'services/clients/authClient';

import {
  AcceptTermsRequest,
  AcceptTermsResponse,
  SignInRequest,
  SignInResponse,
} from './types';

export const signIn = async (
  payload: SignInRequest,
): Promise<SignInResponse> => {
  return await authClient.post('v1/login/shopkeeper', payload);
};

export const acceptTerms = async (
  payload: AcceptTermsRequest,
  token: string,
): Promise<AcceptTermsResponse> => {
  return await authClient.post('/v1/shopkeeper/terms-conditions', payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
