/* eslint-disable prettier/prettier */
import { Button } from 'components/Button';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import { Link } from 'react-router-dom';
import { acceptTerms } from 'services/auth/authService';

type ModalAcceptNewTermsProps = {
  onClose: () => void;
  public_content_id: string;
};

function ModalAcceptNewTerms({
  onClose,
  public_content_id,
}: ModalAcceptNewTermsProps) {
  const { session, signOut } = useAuth();
  const { toast } = useToast();

  const handleClickAccept = async () => {
    const res = await acceptTerms(
      {
        public_content_id,
        isAccepted: true,
      },
      session?.access_token!,
    );
    if (res.successful) {
      onClose();
    } else {
      console.log(res.errors);
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: res.message,
        duration: 3000,
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <div className="pt-5 text-center text-base font-libre">
        <div className="font-bold">Hemos actualizado nuestros</div>
        <Link
          to={'/terms/terms'}
          className="font-bold text-[#3131DD] underline"
        >
          Términos y condiciones
        </Link>{' '}
        <div>
          Por favor acéptalos para ofrecerte una más segura y mejor experiencia.
        </div>
      </div>
      <Button variant={'containeTertiary'} onClick={handleClickAccept}>
        Aceptar y continuar
      </Button>
      <Button variant={'ghost'} onClick={() => signOut()}>
        No, cerrar sesión
      </Button>
    </div>
  );
}

export default ModalAcceptNewTerms;
