/* eslint-disable prettier/prettier */
import { Button } from 'components/Button';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import { Link } from 'react-router-dom';
import { acceptTerms } from 'services/auth/authService';

type ModalAcceptTermsFirstTimeProps = {
  onClose: () => void;
};

type AcceptRejectActions = 'accept' | 'reject';

function ModalAcceptTermsFirstTime({
  onClose,
}: ModalAcceptTermsFirstTimeProps) {
  const { session, signOut, resetInfoTerms } = useAuth();
  const { toast } = useToast();

  const handleClickButton = async (action: AcceptRejectActions) => {
    const isAccepted = action === 'accept' ? true : false;
    const res = await acceptTerms(
      {
        public_content_id: session?.public_content_id!,
        isAccepted,
      },
      session?.access_token!,
    );
    if (res.successful) {
      if (action === 'accept') {
        resetInfoTerms();
        onClose();
      } else {
        signOut();
      }
    } else {
      console.log(res.errors);
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: res.message,
        duration: 3000,
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <div className="pt-5 text-center text-base font-libre">
        Acepta nuestros{' '}
        <Link
          to={'/terms/terms'}
          className="font-bold text-[#3131DD] underline"
        >
          Términos y condiciones
        </Link>{' '}
        y accede a los beneficios de Descuentón.
      </div>
      <Button variant={'containeTertiary'} onClick={() => handleClickButton('accept')}>
        Aceptar y continuar
      </Button>
      <Button variant={'ghost'} onClick={() => handleClickButton('reject')}>
        No, cerrar sesión
      </Button>
    </div>
  );
}

export default ModalAcceptTermsFirstTime;
