import {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ToastAction } from 'components/Toast';
import useSecureStorage from 'hooks/useSecureStorage';
import { useToast } from 'hooks/useToast';
import authService, { AuthSession, SignInRequest } from 'services/auth';
import shopkeeperService, { Shopkeeper } from 'services/shopkeeper';
import { resetAllStores } from 'stores/utils';

interface AuthContextProps {
  isError: boolean;
  isLoading: boolean;
  isSignedIn: boolean;
  session: AuthSession | null;
  shopkeeper: Shopkeeper | null;
  getShopkeeperProfile: () => Promise<void>;
  signIn: (payload: SignInRequest) => Promise<void>;
  signOut: () => void;
  resetInfoTerms: () => void;
}

const initialData: AuthContextProps = {
  isError: false,
  isLoading: false,
  isSignedIn: false,
  session: null,
  shopkeeper: null,
  getShopkeeperProfile: async () => {},
  signIn: async () => {},
  signOut: () => {},
  resetInfoTerms: () => {},
};

export const AuthContext = createContext<AuthContextProps>(initialData);

function AuthProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const [session, setSession, removeSession] =
    useSecureStorage<AuthSession | null>('session', null);
  const [shopkeeper, setShopkeeper, removeShopkeeper] =
    useSecureStorage<Shopkeeper | null>('shopkeeper', null);
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const signIn = useCallback(
    async (payload: SignInRequest) => {
      try {
        setIsLoading(true);
        const response = await authService.signIn(payload);
        setSession(response.data);
        setIsLoading(false);
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            method: 'Numero telefonico',
            userId: `$UID${payload.phone_number}`,
            nudBodega: response.data.shopkeeper_id.toString().replace('-', '|'),
          },
        });
        navigate('/', { replace: true });
      } catch (error: any) {
        setIsLoading(false);
        Sentry.captureException(error, {
          tags: {
            phone_number: payload.phone_number,
            flow: 'login_tenderos',
          },
        });
        TagManager.dataLayer({
          dataLayer: {
            event: 'user_flow',
            category: 'system_message',
            action: 'error',
            label: error.message,
          },
        });
        toast({
          action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
          description: error.message,
          duration: 3000,
          variant: 'destructive',
        });
      }
    },
    [navigate, setSession, toast],
  );

  const signOut = useCallback(() => {
    window.location.replace('/login');
    resetAllStores();
    localStorage.clear();
  }, []);

  const getShopkeeperProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await shopkeeperService.getShopkeeperProfile({
        shopkeeper_id: session?.shopkeeper_id ?? 0,
      });
      setShopkeeper(response.data);
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'success',
          label: 'Iniciaste sesión',
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: 'Iniciaste sesión',
        duration: 2000,
      });
    } catch (error: any) {
      setIsError(true);
      Sentry.captureException(error, {
        tags: {
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          flow: 'get_shopkeeper_profile',
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'error',
          label: error.message,
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: error.message,
        duration: 3000,
        variant: 'destructive',
      });
    }

    setIsLoading(false);
  }, [session?.shopkeeper_id, setShopkeeper, toast]);

  const resetInfoTerms = useCallback(() => {
    setSession({
      ...session!,
      has_accepted_terms: true,
      is_first_login: false,
    });
  }, [session, setSession]);

  const values = useMemo<AuthContextProps>(() => {
    return {
      isError,
      isLoading,
      isSignedIn: !!session?.access_token,
      session,
      shopkeeper,
      getShopkeeperProfile,
      signIn,
      signOut,
      resetInfoTerms,
    };
  }, [
    isError,
    isLoading,
    session,
    shopkeeper,
    getShopkeeperProfile,
    signIn,
    signOut,
    resetInfoTerms,
  ]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
